import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`25 Calorie Assault Bike`}</p>
    <p>{`25 Full Snatch (95/65)`}</p>
    <p>{`25 Burpees Over Bar`}</p>
    <p>{`20 Calorie Assault Bike`}</p>
    <p>{`20 Full Snatch`}</p>
    <p>{`20 Burpees Over Bar`}</p>
    <p>{`15 Calorie Assault Bike`}</p>
    <p>{`15 Full Snatch`}</p>
    <p>{`15 Burpees Over Bar`}</p>
    <p>{`10 Calorie Assault Bike`}</p>
    <p>{`10 Full Snatch`}</p>
    <p>{`10 Burpees Over Bar`}</p>
    <p>{`5 Calorie Assault Bike`}</p>
    <p>{`5 Full Snatch`}</p>
    <p>{`5 Burpees Over Bar`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      